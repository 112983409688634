<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('course_transfers_form')" :isFilter="false" />
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('course_transfers_form')" :isFilter="false" />
			</template>
			<div v-if="showForm">
				<div class="row data-form">
					<div class="col-12">
						<div class="mb-4">
							<h6 class="text-uppercase mb-0">{{ $t('student_information') | toUpperCase }}</h6>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('student_number_long')">
							<span v-if="studentFormData.student_number">{{ studentFormData.student_number }}</span>
							<span v-else>-</span>
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('name_surname')">
							<span v-if="studentFormData.full_name">{{ studentFormData.full_name }}</span>
							<span v-else>-</span>
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('nationality')">
							<span v-if="$i18n.locale == 'tr'">{{ studentFormData.nationality }}</span>
							<span v-else>{{ studentFormData.nationality_en }}</span>
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('faculty')">
							<span v-if="$i18n.locale == 'tr'">{{ studentFormData.faculty_name }}</span>
							<span v-else>{{ studentFormData.faculty_name_en }}</span>
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('class')">
							<span v-if="$i18n.locale == 'tr'">{{ studentFormData.class }}</span>
							<span v-else>{{ studentFormData.class_en }}</span>
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('program')">
							<span v-if="$i18n.locale == 'tr'">{{ studentFormData.program_name }}</span>
							<span v-else>{{ studentFormData.program_name_en }}</span>
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('registration_type')">
							<span>{{ getLocaleText(studentFormData, 'registration_type_text') }}</span>
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('cgpa')">
							<span>{{ getObjectValue(studentFormData, 'cgpa') }}</span>
						</b-form-group>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<b-form-group :label="$t('academic_season_term_applied')">
							<span>{{ getObjectValue(studentFormData, 'academic_year')
								+ '/' + getLocaleText(studentFormData, 'semester_text') }}</span>
						</b-form-group>
					</div>
				</div>

				<hr>
				<div class="my-1 d-inline-block"></div>
				<div class="row">
					<div class="col-12">
						<b-alert show variant="secondary" v-html="info_text"></b-alert>
					</div>
				</div>
				<hr>
				<div class="my-3 d-inline-block"></div>
				<div class="mb-4">
					<h6 class="text-uppercase mb-0">{{ $t('documents') | toUpperCase }}</h6>
				</div>
				<div class="row">
					<div class="col-12 col-md-12">
						<ValidationObserver ref="documentForm">
							<div class="row">
								<div class="col-12 col-md-6">
									<ValidationProvider name="country" rules="required" v-slot="{ valid, errors }">
										<b-form-group>
											<label>{{ $t('country') }}</label>
											<country-selectbox :validateError="errors[0]"
												v-model="documentForm.country"></country-selectbox>
										</b-form-group>
									</ValidationProvider>
								</div>
								<div class="col-12 col-md-6">
									<ValidationProvider name="university" rules="required" v-slot="{ valid, errors }">
										<b-form-group>
											<label>{{ $t('documents_university') }}</label>
											<university-selectbox :countryId="documentForm.country"
                                                                  :status="documentForm.status"
												:validateError="errors[0]" :isCountryRequired="true"
												v-model="documentForm.university"></university-selectbox>
										</b-form-group>
									</ValidationProvider>
								</div>
								<div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
									<ValidationProvider name="files" :rules="fileRequired?'required':''" v-slot="{ valid, errors }">
										<b-form-group :label="$t('upload_your_documents')">
											<div class="d-flex custom-file-upload">
												<b-form-file v-model="documentForm.files"
													:state="errors[0] ? false : (valid ? true : null)"
													:placeholder="$t('select_file')"
													accept="image/jpeg, image/png, image/gif, image/jpg, application/pdf, .doc, .docx, application/msword"
													:drop-placeholder="$t('drop_file')" ref="fileInput"
													multiple></b-form-file>
												<b-button variant="outline-secondary"
													@click="$refs.fileInput.$el.childNodes[0].click();">
													{{ $t('browse') }}
												</b-button>
											</div>
											<div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
										</b-form-group>
									</ValidationProvider>
								</div>
								<div class="col-12">
									<processing-button :processing="uploading" label="upload" @click="uploadFiles">
									</processing-button>
								</div>
							</div>
						</ValidationObserver>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col-12 col-md-12">
						<b-table striped hover :items="documents" :fields="fields">
							<template #cell(delete)="data">
								<b-button type="button" class="text-danger" variant="link" size="xs"
									@click="deleteFile(data.value)">
									{{ $t('delete') | toUpperCase }}
								</b-button>
							</template>
						</b-table>
					</div>
				</div>
				<div class="row mt-4">

					<div class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
						<b-button type="button" variant="primary" @click="sendRequest"
							v-if="(documents.length > 0)">
							{{ $t('send_request') | toUpperCase }}
						</b-button>
					</div>
				</div>
				<CommonModal ref="verifyModal">
					<template v-slot:CommonModalTitle>
						<div>{{ $t('attention') }}</div>
					</template>
					<template v-slot:CommonModalContent>
						<div>
							<b-form-group>{{ $t('course_transfer_verify_text') }}</b-form-group>
							<div class="d-flex">
								<processing-button :processing="saving" label="yes" variant="primary" class="mr-3"
									@click="sendForm">
								</processing-button>
								<b-button type="submit" variant="outline-secondary"
									@click="$refs.verifyModal.$refs.commonModal.hide()">{{ $t('no') }}
								</b-button>
							</div>
						</div>
					</template>
				</CommonModal>

			</div>
		</app-layout>
	</div>
</template>
<script>

// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Components
import CommonModal from "@/components/elements/CommonModal";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox";

// Services
import CourseTransferService from "@/services/CourseTransferService";
import DashboardService from '@/services/DashboardService'

// Others
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ProcessingButton from "@/components/elements/ProcessingButton";
import qs from "qs";

export default {
	components: {
		ProcessingButton,
		AppLayout,
		Header,
		HeaderMobile,
		CommonModal,
		CountrySelectbox,
		UniversitySelectbox,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			studentFormData: {},
			documentForm: {
				country: null,
				university: null,
				files: null,
				course_transfer_id: null,
                status: "a"
			},

			documents: [],

			fields: null,
			showForm: false,
			uploading: false,
			saving: false,
            fileRequired: true,
			info_text: null
		}
	},
	metaInfo() {
		return {
			title: this.$t("course_transfers_form")
		}
	},
	methods: {
		getStudentFormData() {
			CourseTransferService.getFormData()
				.then(response => {
					let data = response.data.data
					this.studentFormData = data
					if (data.course_transfer != null) {
						this.documentForm.course_transfer_id = data.course_transfer.id;
						data.course_transfer.files.map(item => {
							this.getFiles(item);
						});
					}
					this.showForm = true;
				})
				.catch(e => {
					this.showErrors(e);
				})
		},
		async sendRequest() {
			if ((this.documentForm.course_transfer_id != null && this.documents.length != 0)) {
				this.$refs.verifyModal.$refs.commonModal.show()
			}
			else {
				this.$toast.error(this.$t('documents_are_required'))
			}

		},
		sendForm() {
            if(this.documentForm.course_transfer_id==null){
                return;
            }
			this.saving = true;
			CourseTransferService.start(this.documentForm.course_transfer_id)
				.then(response => {
					this.$toast.success(this.$t('course_transfer_success_text'))
					this.$router.push('/my-requests/3') /// send to 3. tab

				})
				.catch(e => {

				})
				.finally(() => {
					this.saving = false;
				})
		},
		async uploadFiles() {
			const isValid = await this.$refs.documentForm.validate();
			if (isValid) {
				if (this.documentForm.course_transfer_id == null) {
					await this.getCourseTransferId();
				}
				this.storeFile();
			}
		},

		async getCourseTransferId() {
			await CourseTransferService.postFormData()
				.then(response => {
					this.documentForm.course_transfer_id = response.data.data.id
				})
				.catch(e => {
					this.showErrors(e)
				})
		},

		storeFile() {
			if (this.documentForm.course_transfer_id != null) {
				this.uploading = true;

				if (!this.fileRequired && this.documentForm.files == null) {
					let formData = new FormData()
					formData.append('course_transfer_id', this.documentForm.course_transfer_id)
					formData.append('university_id', this.documentForm.university)

					CourseTransferService.uploadFile(formData).then(response => {
						let data = response.data.data
						this.getFiles(data)
					}).catch(e => {
						if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
							if (e.data.message) {
								this.$refs.documentForm.errors.country.push(this.$t('api.' + e.data.message));
								this.$toast.error(this.$t('api.' + e.data.message));
							}
						}
						else {
							if (e.data.errors.university_id) {
								this.$refs.documentForm.errors.university.push(e.data.errors.university_id[0]);
							}
							if (e.data.errors.file) {
								this.$refs.documentForm.errors.files.push(e.data.errors.file[0]);
							}
						}
					})
						.finally(() => {
							this.uploading = false;
						})

					return;
				}

				let uploadCount = this.documentForm.files.length;
				this.documentForm.files.forEach(file => {
					let formData = new FormData()
					formData.append('file', file)
					formData.append('course_transfer_id', this.documentForm.course_transfer_id)
					formData.append('university_id', this.documentForm.university)
					CourseTransferService.uploadFile(formData).then(response => {
						let data = response.data.data
						this.getFiles(data)
					}).catch(e => {
						if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
							if (e.data.message) {
								this.$refs.documentForm.errors.country.push(this.$t('api.' + e.data.message));
								this.$toast.error(this.$t('api.' + e.data.message));
							}
						}
						else {
							if (e.data.errors.university_id) {
								this.$refs.documentForm.errors.university.push(e.data.errors.university_id[0]);
							}
							if (e.data.errors.file) {
								this.$refs.documentForm.errors.files.push(e.data.errors.file[0]);
							}
						}
					})
						.finally(() => {
							uploadCount--;
							this.uploading = uploadCount > 0;
							if (uploadCount == 0) {
								this.documentForm.country = null;
								this.documentForm.university = null;
								this.documentForm.files = null;
								this.$refs.documentForm.reset();
							}
						})
				})
			}
		},

		createFields() {
			this.fields = [
				{
					key: 'country',
					label: this.toUpperCase('country')
				},
				{
					key: 'university',
					label: this.toUpperCase('university')
				},
				{
					key: 'document',
					label: this.toUpperCase('document')
				},
				{
					key: 'delete',
					label: '',
					thClass: 'width-50'
				}
			]
		},

		getFiles(data) {
			if (!data.files && data.university_id == this.studentFormData.university_id) {
				this.documents.push({
					delete: data.id,
					country: data.university.country_name,
					university: data.university.name,
					document: ''
				})
			}

			if (!data.files) {
				return;
			}
			data.files.forEach(file => {
				this.documents.push({
					delete: file.uuid,
					country: data.university.country_name,
					university: data.university.name,
					document: file.file_name
				})
			})

		},

		deleteFile(fileId) {
			this.documents.forEach((file, index) => {
				if (file.delete == fileId) {
					this.$swal.fire({
						text: this.$t('are_you_sure_to_delete'),
						showCancelButton: true,
						confirmButtonText: this.$t('yes'),
						cancelButtonText: this.$t('no')
					})
						.then((result) => {
							if (!result.isConfirmed) {
								return;
							}

							CourseTransferService.deleteFile(fileId)
								.then(response => {
									this.$toast.success(this.$t('document_is_deleted'))
									this.documents.splice(index, 1)
								})
								.catch(e => {

								})
						})
				}
			})
		},
        getUserGuide() {
            let config = {
                params: {
                    language: this.$i18n.locale,
                    timer: new Date()
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            DashboardService.getUserGuide(config)
                .then(response => {
                    this._downloadFile(response, 'BAU-' + this.$t('file_name_student_information_system') + '-' + this.$t('file_name_user_guide') + '.pdf')
                })
                .catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message));
                })
        },
		getInfoText() {
			this.info_text = this.$t('course_transfer_form_info')
            this.info_text = this.info_text.replace('#', 'javascript:void(0)" onclick="window.getUserGuide()');
		}
	},
	created() {
		this.getStudentFormData()
		this.createFields()
		this.getInfoText()
        window.getUserGuide = this.getUserGuide.bind(this);
	},
	watch: {
		'documentForm.university'(newVal) {
			this.fileRequired = true;
			if (newVal == this.studentFormData.university_id && this.studentFormData.all_programs && this.studentFormData.all_programs.length > 0) {
				this.fileRequired = false;
			}
		}
	}
}
</script>
